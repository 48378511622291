import React, { useState } from 'react';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';
import { IconButton } from '@material-ui/core';
import DrawerMenuList from './DrawerMenuList';

const DrawerButton = () => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  return (
    <div className="drawerButtonContainer">
      <IconButton
        color="inherit"
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        className="drawerButton"
      >
        <MenuIcon />
      </IconButton>
      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
        <DrawerMenuList />
      </Drawer>
    </div>
  );
};

export default DrawerButton;
