import React from 'react';
import { Link } from 'gatsby';

const navTitles = [
  {
    name: 'INICIO',
    link: '/',
  },
  {
    name: 'SOBRE NOSOTROS',
    link: '/Nosotros',
  },
  {
    name: 'CURSOS',
    link: '/Cursos',
  },
  {
    name: 'EXÁMENES',
    link: '/Examenes',
  },
  {
    name: 'VIAJES',
    link: '/Viajes',
  },
];

const DrawerMenuList = () => {
  return (
    <div className="drawerListContainer">
      {navTitles.map(value => (
        <Link to={value.link}>
          <div className="drawerValueContainer">
            <p className="drawerValue">{value.name}</p>
          </div>
        </Link>
      ))}
      <Link to="/Inscripcion">
        <button className="button">Inscribite ahora</button>
      </Link>
    </div>
  );
};

export default DrawerMenuList;
